/* Global Styles */



:root {
  --primary-color: #17a2b8;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
  --ascorel-color: #076daf;
  white-space: pre-line;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Raleway', sans-serif;
  font-size: 0.8rem;
  line-height: 1;
  background-color: #fff;
  color: #333;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

.link_white {
  color: #FFF;
  text-decoration: underline;
}
.link_white:hover {
   color: #83b6d7
}


ul {
  list-style: none;
}

img {
  width: 100%;
}

/* Utilities */
.container {
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 6rem;
  margin-bottom: 3rem;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-ascorel {
  color: var(--ascorel-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-detail {
  font-size: 0.9rem;
  line-height: 1;
}

.text-detail-coche-option {
  font-size: 0.95rem;
  line-height: 1.6;
}

.text-navbar {
  font-size: 1rem;
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}

.btn {
  display: inline-block;
  /* background: var(--light-color); */
  /* color: #333; */
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.badge {
  font-size: 0.8rem;
  /* padding: 0.1rem; */
  text-align: center;
  /* margin: 0.3rem; */
  /* background: var(--light-color); */
  color: #333;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  /* background: var(--light-color); */
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  /* background: var(--primary-color); */
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  /* background: var(--light-color); */
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  /* background: var(--dark-color); */
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn-ascorel,
.bg-ascorel,
.badge-ascorel,
.alert-ascorel {
  background: var(--ascorel-color);
  color: #fff;
  border: #076daf solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

.line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
}

/* Overlay */
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Forms */
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form .form-text-white {
  display: block;
  margin-top: 0.3rem;
  color: white;
}


.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form select {
  display: block;
  width: 100%;
  padding: 0.1rem;
  font-size: 0.95rem;
  border: 1px solid #ccc;
}

.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

.form input[type='submit'],
button {
  font: inherit;
}

.checkbox {
  display: flex; 
  align-items: center;

}

.table th,
.table td {
  padding: 0.6rem;
  text-align: left;
}

/* .table th {
  background: var(--light-color);
} */

tr.sticky th {
  background: var(--light-color);
  position: sticky;
  top: 65px; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 60px;
  top: 0;
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: var(--primary-color);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}


.navbar-bg {
  background: var(--ascorel-color);
  color: #fff;
}


/* Landing Page */
.landing {
  position: relative;
  /* background: url('./img/black bgnd.jpg') no-repeat center center/cover; */
  background: url('./img/landing_code_gen_5.jpg') no-repeat center center/cover;
  height: 100vh;
  margin-top: 1rem;

}

.landing-inner {
  color: #fff;
  height: 100%;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Profiles Page */
.profile {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  align-items: center;
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
}

/* Profile Page */
.profile-grid {
  display: grid;
  grid-template-areas:
    'top top'
    'about about'
    'exp edu'
    'github github';
  grid-gap: 1rem;
}

.profile-top {
  grid-area: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-top-disabled {
  grid-area: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: .4;
}

.profile-top img {
  width: 50px;
}

.profile-top .icons a {
  color: #fff;
  margin: 0 0.3rem;
}

.profile-top .icons a:hover {
  color: var(--dark-color);
}

.profile-about {
  grid-area: about;
  text-align: center;
}

/* Dashboard Page */
.parent-flex {
  display: flex;
}

.child-left-auto {
  margin-left: auto;
}

.link-button-small {
  font-size: 0.8rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
  /* margin: 0;
  padding: 0; */
}

.link-button {
  font-size: 1rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin-left: 20px;

  /* margin: 0;
  padding: 0; */
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.input {
  display: inline-block;
}

.alert-wrapper {
  position: fixed;
  top: 4rem;
  right: 2rem;
  display: inline-block;
}

.icon-center {
  display: block;
  margin: auto;
}

.card-style-activate  {
  background-color: var(--ascorel-color);
  height: 30rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-style-code  {
  background-color: #A6C8DB;
  width: 18rem;
}

.card-style-code:hover,
.card-style-code:focus   {
  opacity: 0.7;
}

.card-style-users  {
  background-color: #CAE8DA;
  width: 18rem;
}

.card-style-users:hover,
.card-style-users:focus   {
  opacity: 0.7;
}

.card-style-entity  {
  background-color: #FFCFCF;
  width: 18rem;
}

.card-style-entity:hover,
.card-style-entity:focus   {
  opacity: 0.7;
}

.card-header-color-title  {
  margin: 1rem;
  color: black;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.card-header-color-title-activate  {
  margin: 1rem;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.card-header-color  {
  margin: 1rem;
  color: black;
  font-size: 1.0rem;
}

.card {
  border-radius: 0px;
  border-style: none;
}

.faq-title {
  font-weight: bold;
}

.faq-msg {
  font-size: 1rem;
  white-space: pre-line;  
}

.cgu-title {
  font-weight: bold;
  font-size: 1.5rem;
}

.cgu-subtitle {
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--ascorel-color);
  margin-top: 2rem;
  margin-bottom: 0.7rem;

}

.cgu-txt {
  font-size: 1rem;
  text-align: justify;
}

.cgu-txt-bold {
  font-size: 1rem;
  text-align: justify;
  font-weight: bold;
}



/* .row {
  white-space: nowrap;
}

.row > div {
  width: 100%;
  display: inline-block;
} */

@media screen and (max-width: 1000px) {
  .upload-section {
    flex-direction: column;

    /* & > div {
        width: 100%;
      } */
  }
  .upload-section > div {
    width: 100%;
  }

  .image-preview {
    margin-left: 0;
    height: 300px;
  }
  .upload-section {
    height: unset;
  }
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 25px;
  }
  .drop-zone {
    width: 100%;
  }
}

/* Mobile Styles */
@media (max-width: 700px) {
  .container {
    margin-top: 10rem;
    overflow: hidden;
    padding: 0 1rem;
  }

  .hide-sm {
    display: none;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Navbar */
  .navbar {
    display: block;
    text-align: center;
    height: 130px;
  }
  .titre-navbar {
    font-size: 1.0rem;
  }


  .navbar ul {
    text-align: center;
    justify-content: center;
  }

  .navbar h1 {
    margin-bottom: 1rem;
  }

  .navbar .welcome {
    display: none;
  }

  .text-navbar {
    /* display: none; */
  }

  .hide-mobile {
    display: none;
  }

  /* Profiles Page */
  .profile {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .profile ul {
    display: none;
  }


}
